<template>
  <component :is="tag"
             class="whitespace-nowrap disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed shadow-none"
             :class="`${sizeCssClasses} ${colorCssClasses} ${additionalCssClasses}`"
  >
    <svg class="animate-spin w-4 h-4 fill-current shrink-0" viewBox="0 0 16 16" v-show="loading">
      <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
    </svg>
    <span :class="{'ml-2': loading}">
      <slot />
    </span>
  </component>
</template>

<script setup lang="ts">
interface ButtonProps {
  loading?: boolean;
  tag?: string|Object;
  theme?: 'default' | 'orange' | 'red' | 'black',
  size?: null | 'xs' | 'sm' | 'lg',
  fullRounded?: boolean,
}

const props = withDefaults(defineProps<ButtonProps>(), {
  loading: false,
  tag: 'button',
  theme: 'default',
  size: null,
});

const colorCssClasses = computed(() => {
  switch (props.theme) {
    case 'default':
      return 'border-meclee-blue-200 hover:border-meclee-black-200 text-meclee-blue-500 hover:text-meclee-black-200';
    case 'orange':
      return 'border-gray-300 hover:border-gray-400 text-amber-500 hover:text-amber-600';
    case 'red':
      return 'border-gray-300 hover:border-gray-400 text-rose-500 hover:text-rose-600';
    case 'black':
      return 'border-gray-300 hover:border-gray-400 text-meclee-black-500 hover:text-meclee-black-600';
  }
});
const sizeCssClasses = computed(() => {
  switch (props.size) {
    case null:
      return 'btn';
    case 'xs':
      return 'btn-xs';
    case 'sm':
      return 'btn-sm';
    case 'lg':
      return 'btn-lg';
  }
});
const additionalCssClasses = computed(() => {
  if (props.fullRounded) {
    return 'rounded-full';
  }
});
</script>

<style scoped>

</style>
